import { IoMdStar, IoIosStarHalf, IoMdStarOutline } from "react-icons/io";
import tailwindConfig from '@tailwind.config'

const StarRating = ({ rating }) => {
  const getStars = () => {
    const stars = [];
    
    for (let i = 1; i <= 5; i++) {
      if (rating >= i) {
        // Full star
        stars.push(<IoMdStar 
            key={i} 
            color={tailwindConfig.theme.extend.colors.third} 
            size={30} 
        />);
      } else if (rating >= i - 0.5) {
        // Half star
        stars.push(<IoIosStarHalf  
            key={i}  
            color={tailwindConfig.theme.extend.colors.third} 
            size={30} 
        />);
      } else {
        // Empty star
        stars.push(<IoMdStarOutline 
            key={i} 
            color={tailwindConfig.theme.extend.colors.third} 
            size={30} 
        />);
      }
    }
    return stars;
  };

  return <div className="flex flex-row gap-1 items-center justify-center">{getStars()}</div>;
};

export default StarRating;