export const contactReason = {
    'en': [{
        name: 'Buy',
        value: 'buy'
    },
    {
        name: 'Rent',
        value: 'rent'
    },
    {
        name: 'Sell',
        value: 'sell'
    },
    {
        name: 'Other',
        value: 'other'
    }],
    'cn': [{
        name: '购买',
        value: 'buy'
    },
    {
        name: '出租',
        value: 'rent'
    },
    {
        name: '卖出',
        value: 'sell'
    },
    {
        name: '其他',
        value: 'other'
    }],
    'th': [{
        name: 'ซื้อ',
        value: 'buy'
    },
    {
        name: 'ขาย',
        value: 'rent'
    },
    {
        name: 'เช่า',
        value: 'sell'
    },
    {
        name: 'อื่นๆ',
        value: 'other'
    }],

}