'use client'
import React from 'react';
// import { FacebookProvider, Page } from 'react-facebook';
import Script from 'next/script';
import FacebookProvider from './FacebookProvider';

const FacebookTimeline = async ({ children }) => {
  return (
    <FacebookProvider>
      <Script async defer crossorigin="anonymous" src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v19.0&appId=309763885743805" nonce="ilXNuaEl" />
      <div className='sm:block hidden rounded-md overflow-hidden'>
        <iframe title='Facebook Feed' src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FPropertyspace.co.th&tabs=timeline&width=600&height=470&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=309763885743805" width="600" height="470" className=''  scrolling="no" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
      </div>
      <div className='block sm:hidden rounded-md overflow-hidden'>
        <iframe title='Facebook Feed' src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FPropertyspace.co.th&tabs=timeline&width=380&height=400&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=309763885743805" width="380" height="400" className=''  scrolling="no" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
      </div>
      {/* <div class="fb-page" data-href="https://www.facebook.com/propertySpace.Th" data-tabs="timeline" data-width="" data-height="" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://www.facebook.com/propertySpace.Th" class="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/propertySpace.Th">Facebook</a></blockquote></div> */}
    </FacebookProvider>
  )
}

export default FacebookTimeline