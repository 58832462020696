'use client'
import Image from 'next/image'
import parse from 'html-react-parser';
import StarRating from '@components/StarRating'

const TestamonialCard = ({ testamonial, about = false, dictionary, lang }) => {


    return (
        <div className={`${about ? 'w-full' : 'sm:w-1/2 w-full'} sm:p-2 p-0 sm:mb-0 mb-2`}>
            <div className='w-full h-[250px] border-[1px] border-forth rounded-md overflow-hidden'>
                <div className='w-full h-[100px] flex items-center bg-maincolor p-4'>
                    <div className='relative  w-[60px] h-[60px] aspect-[4/3] border-[1px] border-third rounded-full overflow-hidden'>
                        <Image
                            className='object-contain cursor-pointer'
                            // src={agent.cover}
                            src={testamonial.cover}
                            // width={imageWidth}
                            // height={imageHeight}
                            fill={true}
                            alt={`Image of reviewer ${testamonial.name}`}
                        />
                    </div>
                    <div className='flex flex-col items-start pl-4'>
                        <span className='text-xl text-light'>{testamonial.name}</span>
                        <StarRating rating={parseFloat(testamonial.rating)} />
                    </div>
                </div>
                <div className='w-full h-[150px] flex items-center p-4 mb-light line-clamp-4 italic'>{parse(`${testamonial.content}`)}</div>
            </div>
        </div>
    )
}

export default TestamonialCard