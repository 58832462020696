import React from 'react'
import Image from 'next/image'
import Link from 'next/link'
import LinkComponent from '@components/LinkComponent'

const SmallSwiperImage = ({ title, slug, id, imageSrc, route, bgColor = 'bg-maincolortrans' }) => {
    const imageLink = imageSrc || `/images/thonglor.png`

    return (
        <LinkComponent href={`/properties?district=${id}`} className='group relative district-swiper-small-image w-[150px] h-[150px]' >
            <div className='w-[290px] h-[140px] relative overflow-hidden rounded-md'>
                <Image
                    src={imageLink}
                    fill='true'
                    className='object-cover'
                    alt={slug}
                />
                <div className='absolute bottom-[-50px] group-hover:bottom-[-20px] duration-150 left-0 right-0 rounded-b-md flex justify-center h-[100px] items-start bg-gradient-to-t from-maintransparent to-opacity-20 overflow-hidden'>
                    <h3 className={`text-light z-10 px-1 rounded-sm text-xl text-center`}>{title}</h3>
                </div>
            </div>
        </LinkComponent >
    )
}

export default SmallSwiperImage