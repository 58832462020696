'use client'
import Image from 'next/image'
import LinkComponent from './LinkComponent'
import { sendGAEvent } from '@next/third-parties/google'

const BlogCardHome = ({ blog, imageWidth = 400, imageHeight = 300, dictionary, lang }) => {

    return (
        <div className='relative w-full m-1 rounded-lg shadow-md hover:shadow-sm hover:shadow-maincolor'
        >
            <LinkComponent
                className='w-full'
                href={'/blogs/' + blog.slug}
                onClick={() => sendGAEvent({ 
                    event: "select_content",
                    blog_id: blog._id.toString(),
                    blog_name: blog.title,
                })} 
            >
                <div className='relative w-full aspect-[4/3] rounded-t-md overflow-hidden'>
                    <Image
                        className='object-cover'
                        src={blog?.cloudThumbnail ? blog.cloudThumbnail : '/images/test.jpg'}
                        fill={true}
                        alt={blog.slug}
                    />
                </div>

                <div className='p-2'>
                    <div className='text-maincolor text-xl font-semibold rounded-r-md z-10 h-[60px]'><h3 className='line-clamp-2'>{blog.title}</h3></div>
                    <div className='h-[70px]'>
                        <p className='text-secondary overflow-clip line-clamp-3'>{blog.excerpt}</p>
                    </div>
                    <p className='text-gray-400'>{blog.createdAt?.split('T')?.[0]}</p>
                </div>
            </LinkComponent >
        </div>
    )
}

export default BlogCardHome
