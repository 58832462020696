export const searchTypes = {
    'en': [{
        title: 'Buy',
        name: 'buy',
        value: 'sale'
    },
    {
        title: 'Rent',
        name: 'Rent',
        value: 'rent'
    }],
    'th': [{
        title: 'ซื้อ',
        name: 'Buy',
        value: 'sale'
    },
    {
        title: 'เช่า',
        name: 'Rent',
        value: 'rent'
    }],
    'cn': [{
        title: '买',
        name: '买',
        value: 'sale'
    },
    {
        title: '租',
        name: '租',
        value: 'rent'
    }],
}
// export const searchTypes = [
//     {
//         title: 'Buy',
//         heroTitle: 'Buy',
//         value: 'sale'
//     },
//     {
//         title: 'Rent',
//         heroTitle: 'Rent',
//         value: 'rent'
//     }
// ]