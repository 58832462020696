'use client'
import React, { Component} from 'react';
// import { FacebookProvider, Page } from 'react-facebook';
import Script from 'next/script';

const FacebookProvider = async ({ children }) => {
  return (
    <>  
      <Script
        // src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v19.0&appId=309763885743805&nouce=ilXNuaEl"
        // strategy="lazyOnload"
        // nonce="ilXNuaEl"
        // crossorigin="anonymous"
        // defer
        // onLoad={() =>
        //   console.log(`script loaded correctly, window.FB has been populated`)
        // }
      />
            {children}
      {/* <FacebookProvider appId="309763885743805">
        <div>hi</div>
        <Page href="https://www.facebook.com" tabs="timeline" />
      </FacebookProvider>     */}
    </>
  )
}

export default FacebookProvider