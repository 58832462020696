'use client'
import React, { useState, useEffect } from 'react'
import { FaUser } from 'react-icons/fa'
import { MdOutlinePhoneIphone, MdEmail } from 'react-icons/md'
import { AiFillWechat } from 'react-icons/ai'
import { FaLine } from 'react-icons/fa'
import tailwindConfig from '@tailwind.config'
import { Combobox, Listbox } from "@headlessui/react"
import { countryCodes } from '@constants/countryCodes'
import { RiArrowDropDownLine } from 'react-icons/ri'
import { BiSolidBuildingHouse } from "react-icons/bi"
import { sellSaleTypes } from '@constants/sellSaleTypes';
import { preferredContact } from '@constants/preferredContact';
import { RadioGroup } from '@headlessui/react'
import { FaEnvelopeCircleCheck } from "react-icons/fa6";

const SellForm = ({ dictionary, lang }) => {

    let [sale, setSale] = useState(sellSaleTypes[lang][0].value)
    // let [addChecked, setAddChecked] = useState(saleTypes[0].value)
    const [type, setType] = useState('')
    const [contact, setContact] = useState(preferredContact[lang][0])


    const [userId, setUserId] = useState(null)
    const [phone, setPhone] = useState('')
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [line, setLine] = useState('')
    const [wechat, setWechat] = useState('')
    const [message, setMessage] = useState(``)
    const [hasEnquiry, setHasEnquiry] = useState(false)
    const [selectedCountry, setSelectedCountry] = useState(countryCodes[0].code)
    const [query, setQuery] = useState('')

    const filteredCountries =
        query === ''
            ? countryCodes
            : countryCodes.filter((country) => {
                return country.country.toLowerCase().includes(query.toLowerCase()) || country.code.toLowerCase().includes(query.toLowerCase())
            })

    useEffect(() => {
        const setUserData = () => {
            const savedDetails = JSON.parse(localStorage.getItem('contactDetails'));

            if (savedDetails) {
                if (savedDetails.name && savedDetails.name !== '') {
                    setName(savedDetails.name);
                }
                if (savedDetails.selectedCountry && savedDetails.selectedCountry !== '') {
                    setSelectedCountry(savedDetails.selectedCountry);
                }
                if (savedDetails.phone && savedDetails.phone !== '') {
                    setPhone(savedDetails.phone);
                }
                if (savedDetails.email && savedDetails.email !== '') {
                    setEmail(savedDetails.email);
                }
                if (savedDetails.contact && savedDetails.contact !== '') {
                    setContact(savedDetails.contact);
                }
                if (savedDetails.line && savedDetails.line !== '') {
                    setLine(savedDetails.line);
                }
                if (savedDetails.wechat && savedDetails.wechat !== '') {
                    setWechat(savedDetails.wechat);
                }

            }
        }
        setUserData()
    }, [])

    const handleForm = async (e) => {
        e.preventDefault()

        const contactDetails = {
            name,
            selectedCountry,
            phone,
            email,
            contact,
            line,
            wechat
        };

        localStorage.setItem('contactDetails', JSON.stringify(contactDetails));

        const { sendGAEvent } = await import('@next/third-parties/google')

        sendGAEvent({ 
            event: 'lead_list_withus', 
            user_email: email || 'not_provided', 
            user_phonecall: `${selectedCountry}${phone?.replace(/[a-zA-Z\W]/g, '')}`,
            listing_type: sale
        })

        const { sendSellForm } = await import('@services/sendSellForm')

        await sendSellForm(userId, name, selectedCountry, phone, email, message, sale, type, contact, line, wechat)
        setHasEnquiry(true)
    }

    const selectTextOnClick = (e) => {
        e.target.select();
    };

    return (
        <>
            {!hasEnquiry ? (
                <div className='md:mb-0 pb-3 md:border-b-0 border-b-[1px] border-b-forth flex items-center rounded-md overflow-hidden sm:flex-row'>
                    <div className='w-full border-none sm:border-l-[1px]'>
                        <h2 className='text-maincolor font-semibold text-md mb-1'>{dictionary.fillTheForm}</h2>
                        <form onSubmit={handleForm}>
                            <label className='text-secondary text-md'>{dictionary.want}</label>
                            <div className='z-1 relative w-full cursor-default shadow-md overflow-hidden rounded-lg bg-white text-left text-secondary sm:text-md'>
                                <RadioGroup value={sale} onChange={(value) => {
                                    setSale(value)
                                }}
                                    className='w-full flex flex-row'
                                >
                                    {sellSaleTypes[lang].map((saleType) => (
                                        <RadioGroup.Option
                                            key={saleType.value}
                                            value={saleType.value}
                                            checked={saleType.value === sale}
                                            className={({ active, checked }) =>
                                                `${active
                                                    ? ''
                                                    : ''
                                                }
                                    ${checked ? 'bg-maincolor text-light' : 'bg-light'
                                                }
                                    w-full relative flex cursor-pointer px-2 py-2 shadow-md focus:outline-none`
                                            }
                                        >
                                            {({ active, checked }) => (
                                                <>
                                                    <div className="flex w-full items-center justify-center">
                                                        <div className="flex items-center">
                                                            <div className="text-md text-center">
                                                                <RadioGroup.Label
                                                                    as="p"
                                                                    className={`font-medium w-full  ${checked ? 'text-light' : 'text-maincolor'
                                                                        }`}
                                                                >
                                                                    {saleType.title}
                                                                </RadioGroup.Label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </RadioGroup.Option>
                                    ))}
                                </RadioGroup>
                            </div>
                            <div className='mt-2'>
                                <label className='text-secondary text-md'>{dictionary.typeOfProperty}</label>
                                <div className='z-1 relative w-full cursor-default overflow-hidden rounded-lg shadow-md bg-white text-left border-[1px] border-forth text-secondary focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 sm:text-md'>
                                    <BiSolidBuildingHouse
                                        color={tailwindConfig.theme.extend.colors.secondary}
                                        size={28}
                                        className='absolute inset-y-0 left-2 top-1 flex items-center pr-2'
                                    />
                                    <input
                                        className='w-full border-none py-2 pl-10 text-md leading-5 text-gray-900 focus:ring-0'
                                        placeholder={dictionary.salePlaceholder}
                                        type='text'
                                        name='type'
                                        value={type}
                                        onChange={(e) => setType(e.target.value)}
                                        required
                                    />
                                </div>
                            </div>
                            <div className='mt-2'>
                                <label className='text-secondary text-md'>{dictionary.propertyDescription}</label>
                                <div className='w-full py-1'>
                                    <textarea
                                        className='w-full border-[1px]  shadow-md border-forth text-secondary rounded-md p-2'
                                        value={message}
                                        onChange={(e) => setMessage(e.target.value)}
                                        rows={3}
                                        required
                                        placeholder={dictionary.descriptionPlaceholder}
                                    />
                                </div>
                            </div>
                            <div className='mt-2'>
                                <label className='text-secondary text-md'>{dictionary.name}</label>
                                <div className='z-1 relative w-full cursor-default overflow-hidden rounded-lg  shadow-md bg-white text-left border-[1px] border-forth text-secondary focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 sm:text-md'>
                                    <FaUser
                                        color={tailwindConfig.theme.extend.colors.secondary}
                                        size={28}
                                        className='absolute inset-y-0 left-2 top-1 flex items-center pr-2'
                                    />
                                    <input
                                        className='w-full border-none py-2 pl-10 text-md leading-5 text-gray-900 focus:ring-0'
                                        placeholder={dictionary.namePlaceholder}
                                        type='text'
                                        name='name'
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        required
                                    />
                                </div>
                            </div>
                            <div className='mt-2'>
                                <label className='text-secondary text-md'>{dictionary.preferredContact}</label>
                                <Listbox value={contact} onChange={setContact}>
                                    <div className='z-1 relative w-full cursor-default rounded-lg shadow-md bg-white text-left border-[1px] border-forth text-secondary focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 sm:text-md'>
                                        <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left focus:shadow-md focus:outline-none  sm:text-md">
                                            <span className="block truncate">{contact.name}</span>
                                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                <RiArrowDropDownLine
                                                    color={tailwindConfig.theme.extend.colors.secondary}
                                                    size={28}
                                                />
                                            </span>
                                        </Listbox.Button>
                                        <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-md z-50">
                                            {preferredContact[lang].map((cont, contIdx) => (
                                                <Listbox.Option
                                                    key={contIdx}
                                                    className={({ active }) =>
                                                        `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-gray-100 text-maincolor' : 'text-secondary'
                                                        }`
                                                    }
                                                    value={cont}
                                                >
                                                    {({ selected }) => (
                                                        <>
                                                            <span
                                                                className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                                                    }`}
                                                            >
                                                                {cont.name}
                                                            </span>
                                                            {selected ? (
                                                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                                                    {/* <CheckIcon className="h-5 w-5" aria-hidden="true" /> */}
                                                                </span>
                                                            ) : null}
                                                        </>
                                                    )}
                                                </Listbox.Option>
                                            ))}
                                        </Listbox.Options>
                                    </div>
                                </Listbox>
                            </div>
                            <div className='mt-2'>
                                {(contact.value == 'phone' || contact.value == 'whatsapp' || contact.value == 'viber') && (
                                    <>
                                        <label className='text-secondary text-md'>{dictionary.phone}</label>
                                        <div className='flex flex-row w-full py-1'>
                                            {/* <CountryCode className="w-1/4" /> */}
                                            <Combobox className="w-1/4"
                                                value={selectedCountry}
                                                onChange={setSelectedCountry}
                                            >
                                                <div className="relative w-full">
                                                    <div className='z-1 relative w-full cursor-default overflow-hidden  rounded-lg shadow-md bg-white text-left border-[1px] border-forth text-secondary focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 sm:text-md'>
                                                        <Combobox.Input onClick={selectTextOnClick} onChange={(event) => setQuery(event.target.value)} required className='w-full border-none py-2 pl-3 pr-10 text-md leading-5 text-gray-900 focus:ring-0' />
                                                        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                                                            <RiArrowDropDownLine
                                                                color={tailwindConfig.theme.extend.colors.secondary}
                                                                size={28}
                                                            />
                                                        </Combobox.Button>
                                                    </div>
                                                    <Combobox.Options
                                                        as="ul" // Render the Combobox.Options as an unordered list (ul) to contain the options
                                                        className='z-50 left-0 right-0 absolute h-auto w-[300px] max-h-[200px] overflow-y-auto  rounded-lg shadow-md bg-white text-left border-[1px] border-forth py-1'
                                                    >
                                                        {filteredCountries.map((country) => (
                                                            <Combobox.Option
                                                                key={country.id}
                                                                value={country.code}
                                                                className='py-2 px-4 cursor-pointer color-secondary z-50'
                                                            >
                                                                {country.code} {country.country}
                                                            </Combobox.Option>
                                                        ))}
                                                    </Combobox.Options>
                                                </div>
                                            </Combobox>
                                            <div className='z-1 relative w-full cursor-default overflow-hidden  rounded-lg shadow-md bg-white border-[1px] border-forth text-left text-secondary focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 sm:text-md'>
                                                <MdOutlinePhoneIphone
                                                    color={tailwindConfig.theme.extend.colors.secondary}
                                                    size={28}
                                                    className='absolute inset-y-0 left-2 top-1 flex items-center pr-2'
                                                />
                                                <input
                                                    className='w-full border-none py-2 pl-10 text-md leading-5 text-gray-900 focus:ring-0'
                                                    placeholder={dictionary.phonePlaceholder}
                                                    required
                                                    type='text'
                                                    name='phone'
                                                    value={phone}
                                                    onChange={(e) => setPhone(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </>
                                )}
                                {(contact.value == 'line') && (
                                    <>
                                        <label className='text-secondary text-md'>{dictionary.line}</label>
                                        <div className='w-full py-1'>
                                            <div className='z-1 relative w-full cursor-default overflow-hidden rounded-lg shadow-md bg-white text-left border-[1px] border-forth text-secondary focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 sm:text-md'>
                                                <FaLine
                                                    color={tailwindConfig.theme.extend.colors.secondary}
                                                    size={28}
                                                    className='absolute inset-y-0 left-2 top-1 flex items-center pr-2'
                                                />
                                                <input
                                                    className='w-full border-none py-2 pl-10 text-md leading-5 text-gray-900 focus:ring-0'
                                                    placeholder={dictionary.line}
                                                    type='line'
                                                    name='line'
                                                    value={line}
                                                    onChange={(e) => setLine(e.target.value)}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </>
                                )}
                                {(contact.value == 'wechat') && (
                                    <>
                                        <label className='text-secondary text-md'>{dictionary.wechat}</label>
                                        <div className='w-full py-1'>
                                            <div className='z-1 relative w-full cursor-default overflow-hidden rounded-lg shadow-md bg-white text-left border-[1px] border-forth text-secondary focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 sm:text-md'>
                                                <AiFillWechat
                                                    color={tailwindConfig.theme.extend.colors.secondary}
                                                    size={28}
                                                    className='absolute inset-y-0 left-2 top-1 flex items-center pr-2'
                                                />
                                                <input
                                                    className='w-full border-none py-2 pl-10 text-md leading-5 text-gray-900 focus:ring-0'
                                                    placeholder={dictionary.wechatPlaceholder}
                                                    type='wechat'
                                                    name='wechat'
                                                    value={wechat}
                                                    onChange={(e) => setWechat(e.target.value)}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className='mt-2'>
                                <label className='text-secondary text-md'>{dictionary.email}</label>
                                <div className='w-full py-1'>
                                    <div className='z-1 relative w-full cursor-default overflow-hidden rounded-lg shadow-md bg-white text-left border-[1px] border-forth text-secondary focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 sm:text-md'>
                                        <MdEmail
                                            color={tailwindConfig.theme.extend.colors.secondary}
                                            size={28}
                                            className='absolute inset-y-0 left-2 top-2 flex items-center pr-2'
                                        />
                                        <input
                                            className='w-full border-none py-2 pl-10 text-md leading-5 text-gray-900 focus:ring-0'
                                            placeholder={dictionary.emailPlaceholder}
                                            type='email'
                                            name='email'
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                            <button 
                                // onClick={() => sendGAEvent({ 
                                //     event: 'lead_list_withus', 
                                //     user_email: email || 'not_provided', 
                                //     user_phonecall: `${selectedCountry}${phone?.replace(/[a-zA-Z\W]/g, '')}`,
                                //     listing_type: sale
                                // })} 
                                className='w-full p-2 bg-third text-light font-bold rounded-md' 
                                type='submit'>{dictionary.send}</button>
                        </form>
                    </div>

                </div>) : (
                <div className='xl:mb-0 pb-3 flex flex-col  text-lg sm:flex-row text-center'>
                <div className='w-full flex flex-col items-center'>
                    <h2 className='text-maincolor font-bold text-md'>{dictionary.enquirySent}</h2>
                    <p className='text-secondary'>{dictionary.enquirySentSubtitle}</p>
                    <FaEnvelopeCircleCheck
                        className='mt-4'
                        size={60}
                        color={tailwindConfig.theme.extend.colors.maincolor}
                    />
                </div>
            </div>
            )}
        </>
    )
}

export default SellForm