export const sellSaleTypes = {
    "en": [
        {
            title: 'Sell',
            value: 'sale'
        },
        {
            title: 'Rent',
            value: 'rent'
        }
    ],
    "th": [
        {
            title: 'ขาย',
            value: 'sale'
        },
        {
            title: 'เช่า',
            value: 'rent'
        }
    ],
    "cn": [
        {
            title: '出卖',
            value: 'sale'
        },
        {
            title: '出租',
            value: 'rent'
        }
    ],
}
