'use client'
import React from 'react';
import NewDevelopmentCard from '@components/NewDevelopmentCard';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import LinkComponent from '@components/LinkComponent';
import 'swiper/css';
import 'swiper/css/navigation';

const NewDevelopmentCardSlider = ({ title, description, newDevelopments, moreUrl = '', dictionary, lang, params }) => {
    return (
    <div className='w-full'>
        <div className='2xl:w-10/12 max-w-[1600px] mx-auto flex flex-col my-4 pl-2 md:pl-0'>
            <h1 className='text-maincolor text-2xl font-bold'>{title}</h1>
            <p className='text-maincolor text-xl mb-2'>{description}</p>
            {newDevelopments.length > 0 && (
                <div className='sm:block hidden'>
                    <Swiper
                        // slidesPerView={'auto'}
                        spaceBetween={10}
                        slidesPerView={4}
                        pagination={{
                            clickable: true,
                        }}
                        navigation={true}
                        modules={[Navigation]}
                        className=''
                    >
                        {newDevelopments.map((project, index) => (
                            <SwiperSlide key={index}>
                                <NewDevelopmentCard project={project} dictionary={dictionary.card} lang={lang} />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            )}
            {newDevelopments.length > 0 && (
                <div className='sm:hidden block'>
                    <Swiper
                        spaceBetween={10}
                        slidesPerView={1.5}
                    >
                        {newDevelopments.map((project, index) => (
                            <SwiperSlide key={index}>
                                <NewDevelopmentCard project={project} dictionary={dictionary.card} lang={lang} />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            )}
            {newDevelopments?.length > 0 && moreUrl !== '' && (
                <div className='w-full flex justify-center mb-2 mt-4'>
                    <LinkComponent className='w-[300px] bg-maincolor text-xl text-light text-center rounded-md py-2' href={moreUrl}>{dictionary.section.seeMore}</LinkComponent>
                </div>
            )}
        </div>
    </div>
  )
}

export default NewDevelopmentCardSlider