export const categoryTypes = {
    'en': [
    {
        title: 'Condo',
        value: 'condo'
    },
    {
        title: 'Pool Villa',
        value: 'poolvilla'
    },
    {
        title: 'House',
        value: 'house'
    },
    {
        title: 'Land',
        value: 'land'
    },
    {
        title: 'Commercial',
        value: 'commercial'
    }],
    'th': [
    {
        title: 'คอนโด',
        value: 'condo'
    },
    {
        title: 'บ้าน',
        value: 'house'
    },
    {
        title: 'พูลวิลล่า',
        value: 'poolvilla'
    },
    {
        title: 'ที่ดิน',
        value: 'land'
    },
    {
        title: 'เชิงพาณิชย์',
        value: 'commercial'
    }],
    'cn': [
    {
        title: '公寓',
        value: 'condo'
    },
    {
        title: '别墅',
        value: 'house'
    },
    {
        title: '泳池别墅',
        value: 'poolvilla'
    },
    {
        title: '土地',
        value: 'land'
    },
    {
        title: '工业',
        value: 'commercial'
    }]
}