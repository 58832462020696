'use client'
import SellForm from "@components/SellForm"
import { useState } from "react"
import { Dialog } from '@headlessui/react'
import { AiFillCloseSquare } from 'react-icons/ai'
import tailwindConfig from '@tailwind.config'
import { GiReceiveMoney } from "react-icons/gi";
import { PiNotepadFill } from "react-icons/pi";
import { MdRealEstateAgent } from "react-icons/md";
import { GrUserExpert } from "react-icons/gr";  
import { TbPigMoney } from "react-icons/tb";
import { PiChatsCircleFill } from "react-icons/pi";
import { RiCustomerServiceLine } from "react-icons/ri";

const ServiceCards = ({ dictionary, lang }) => {
    const [needHelpIsOpen, setNeedHelpIsOpen] = useState(false)

    const togglePopup = () => {
        setNeedHelpIsOpen(true)
    }

  return (
    <>
        <div className='w-full bg-maincolor py-4 shadow-md'>
            <div className='md:w-full 2xl:w-10/12 max-w-[1600px] mx-auto flex flex-col sm:px-0 px-3'>
                <div className="w-full flex flex-col gap-4 items-center justify-center text-light">
                    <span className="text-xl">{dictionary.services.subtitle}</span>
                    <h2 className="text-3xl font-bold space-x-2 text-center">{dictionary.services.title}</h2>
                    <span className="text-xl">{dictionary.services.subtitle2}</span>
                </div>
                <div className="w-full items-center justify-center gap-4 mt-4 flex sm:flex-row flex-col">
                    <div className="sm:w-[400px] w-full flex flex-col items-center p-4 gap-4 rounded-md bg-light">
                        <MdRealEstateAgent
                            color={tailwindConfig.theme.extend.colors.maincolor}
                            size={60}
                        />
                        <span className="text-2xl text-maincolor font-bold">{dictionary.services.buyProperties}</span>
                        <div className="h-[70px] mb-4">
                            <p className="text-xl text-center">{dictionary.services.buyPropertiesPrompt}</p>
                        </div>
                        <a className="w-full text-center py-2 bg-third text-light font-bold rounded-md" href="/properties">{dictionary.services.buyButton}</a>
                    </div>
                    <div className="sm:w-[400px] w-full flex flex-col items-center p-4 gap-4 rounded-md bg-light">
                        <GiReceiveMoney
                            color={tailwindConfig.theme.extend.colors.maincolor}
                            size={60}
                        />
                        <span className="text-2xl text-maincolor font-bold">{dictionary.services.sellProperties}</span>
                        <div className="h-[70px] mb-4">
                            <p className="text-xl text-center">{dictionary.services.sellPropertiesPrompt}</p>
                        </div>
                        <button onClick={togglePopup} className="w-full text-center py-2 bg-third text-light font-bold rounded-md">{dictionary.services.sellButton}</button>
                            <Dialog
                                    open={needHelpIsOpen}
                                    onClose={() => setNeedHelpIsOpen(false)}
                                    className="relative z-50"
                                    >
                                    <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
                                    <div className="fixed inset-0 overflow-y-auto flex w-screen items-center justify-center">
                                        <Dialog.Panel className="mx-auto md:w-full lg:w-[600px] rounded bg-white">
                                            <div className='w-full flex justify-between items-start pt-4 px-4'>
                                                <div>
                                                    <h2 className='text-maincolor font-semibold text-xl mb-1'>{dictionary.sellForm.fillTheForm}</h2>
                                                </div>
                                                <button onClick={() => setNeedHelpIsOpen(false)}>
                                                    <AiFillCloseSquare
                                                        color={tailwindConfig.theme.extend.colors.maincolor}
                                                        size={32}
                                                    />
                                                </button>
                                            </div>
                                                <div className='w-full h-full py-6 flex md:flex-row flex-col flex-nowrap md:flex-wrap mx-auto px-0'>
                                                    <div className='sm:w-full w-full bg-light overflow-hidden rounded-md py-2 px-4'>
                                                        <SellForm dictionary={dictionary.sellForm} lang={lang} />
                                                    </div>
                                                </div>
                                        </Dialog.Panel>
                                    </div>
                            </Dialog>
                    </div>
                    <div className="sm:w-[400px] w-full flex flex-col items-center p-4 gap-4 rounded-md bg-light">
                        <PiNotepadFill
                            color={tailwindConfig.theme.extend.colors.maincolor}
                            size={60}
                        />
                        <span className="text-2xl text-maincolor font-bold">{dictionary.services.rentProperties}</span>
                        <div className="h-[70px] mb-4">
                            <p className="text-xl text-center">{dictionary.services.rentPropertiesPrompt}</p>
                        </div>
                        <a className="w-full text-center py-2 bg-third text-light font-bold rounded-md" href="/properties?saleType=rent">{dictionary.services.rentButton}</a>
                    </div>
                </div>
            </div>
        </div>
        <div className='w-full sm:py-8 py-4'>
            <div className='md:w-full 2xl:w-10/12 max-w-[1600px] mx-auto flex sm:flex-row flex-col sm:px-0 px-3'>
                <div className="sm:w-1/3 w-full flex flex-col items-start gap-6">
                    <span className="text-xl text-third font-semibold">{dictionary.services.whyChooseUs}</span>
                    <span className="text-3xl text-maincolor font-bold">{dictionary.services.whyChooseUsTitle}</span>
                    <span className="text-xl text-secndary">{dictionary.services.whyChooseUsText}</span>
                    <a className="sm:w-[200px] w-full text-center py-2 bg-third text-light font-bold rounded-md" href="/about">{dictionary.services.learnMore}</a>
                </div>
                <div className="sm:w-2/3 w-full sm:mt-0 mt-4 flex flex-wrap sm:px-4 px-0">
                    <div className="sm:w-1/2 w-full flex flex-row">
                        <div className="py-4">
                            <TbPigMoney
                                color={tailwindConfig.theme.extend.colors.third}
                                size={32}
                            />
                        </div>
                        <div className="flex flex-col gap-4 p-4">
                            <span className="font-bold text-2xl">{dictionary.services.cardTitle1}</span>
                            <p className="text-xl">{dictionary.services.cardText1}</p>
                        </div>
                    </div>
                    <div className="sm:w-1/2 w-full flex flex-row">
                        <div className="py-4">
                            <GrUserExpert
                                color={tailwindConfig.theme.extend.colors.third}
                                size={32}
                            />
                        </div>
                        <div className="flex flex-col gap-4 p-4">
                            <span className="font-bold text-2xl">{dictionary.services.cardTitle2}</span>
                            <p className="text-xl">{dictionary.services.cardText2}</p>
                        </div>
                    </div>
                    <div className="sm:w-1/2 w-full flex flex-row">
                        <div className="py-4">
                            <RiCustomerServiceLine
                                color={tailwindConfig.theme.extend.colors.third}
                                size={32}
                            />
                        </div>
                        <div className="flex flex-col gap-4 p-4">
                            <span className="font-bold text-2xl">{dictionary.services.cardTitle3}</span>
                            <p className="text-xl">{dictionary.services.cardText3}</p>
                        </div>
                    </div>
                    <div className="sm:w-1/2 w-full flex flex-row">
                        <div className="py-4">
                            <PiChatsCircleFill 
                                color={tailwindConfig.theme.extend.colors.third}
                                size={32}
                            />
                        </div>
                        <div className="flex flex-col gap-4 p-4">
                            <span className="font-bold text-2xl">{dictionary.services.cardTitle4}</span>
                            <p className="text-xl">{dictionary.services.cardText4}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default ServiceCards