export const serviceCondoRoomTypes = {
    'en': [
        {
            title: 'Any',
            value: '0'
        },
        {
            title: 'Studio',
            value: 'studio'
        },
        {
            title: '1',
            value: '1'
        },
        {
            title: '2',
            value: '2'
        },
        {
            title: '3',
            value: '3'
        },
        {
            title: '4',
            value: '4'
        },
        {
            title: '5+',
            value: '5'
        },
    ],
    'th': [
        {
            title: 'ทั้งหมด',
            value: '0'
        },
        {
            title: 'สตูดิโอ',
            value: 'studio'
        },
        {
            title: '1',
            value: '1'
        },
        {
            title: '2',
            value: '2'
        },
        {
            title: '3',
            value: '3'
        },
        {
            title: '4',
            value: '4'
        },
        {
            title: '5+',
            value: '5'
        },
    ],
    'cn': [
        {
            title: '无线',
            value: '0'
        },
        {
            title: '开建户型',
            value: 'studio'
        },
        {
            title: '1',
            value: '1'
        },
        {
            title: '2',
            value: '2'
        },
        {
            title: '3',
            value: '3'
        },
        {
            title: '4',
            value: '4'
        },
        {
            title: '5+',
            value: '5'
        },
    ],

}