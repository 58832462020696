
export const rentMinPrices = {
    "en":[
        {
            value: '0',
            nom: 'Any'
        },
        {
            value: '10000',
            nom: '10,000'
        },
        {
            value: '20000',
            nom: '20,000'
        },
        {
            value: '30000',
            nom: '30,000'
        },
        {
            value: '40000',
            nom: '40,000'
        },
        {
            value: '50000',
            nom: '50,000'
        },
        {
            value: '60000',
            nom: '60,000'
        },
        {
            value: '70000',
            nom: '70,000'
        },
        {
            value: '80000',
            nom: '80,000'
        },
        {
            value: '90000',
            nom: '90,000'
        },
        {
            value: '100000',
            nom: '100,000'
        },
        {
            value: '120000',
            nom: '120,000'
        },
        {
            value: '150000',
            nom: '150,000'
        },
        {
            value: '200000',
            nom: '200,000'
        },
        {
            value: '250000',
            nom: '250,000'
        },
        {
            value: '300000',
            nom: '300,000'
        },
        {
            value: '350000',
            nom: '350,000++'
        }
    
    ],
    "th":[
        {
            value: '0',
            nom: 'อื่นๆ'
        },
        {
            value: '10000',
            nom: '10,000'
        },
        {
            value: '20000',
            nom: '20,000'
        },
        {
            value: '30000',
            nom: '30,000'
        },
        {
            value: '40000',
            nom: '40,000'
        },
        {
            value: '50000',
            nom: '50,000'
        },
        {
            value: '60000',
            nom: '60,000'
        },
        {
            value: '70000',
            nom: '70,000'
        },
        {
            value: '80000',
            nom: '80,000'
        },
        {
            value: '90000',
            nom: '90,000'
        },
        {
            value: '100000',
            nom: '100,000'
        },
        {
            value: '120000',
            nom: '120,000'
        },
        {
            value: '150000',
            nom: '150,000'
        },
        {
            value: '200000',
            nom: '200,000'
        },
        {
            value: '250000',
            nom: '250,000'
        },
        {
            value: '300000',
            nom: '300,000'
        },
        {
            value: '350000',
            nom: '350,000++'
        }
    
    ],
    "cn":[
        {
            value: '0',
            nom: '无线'
        },
        {
            value: '10000',
            nom: '10,000'
        },
        {
            value: '20000',
            nom: '20,000'
        },
        {
            value: '30000',
            nom: '30,000'
        },
        {
            value: '40000',
            nom: '40,000'
        },
        {
            value: '50000',
            nom: '50,000'
        },
        {
            value: '60000',
            nom: '60,000'
        },
        {
            value: '70000',
            nom: '70,000'
        },
        {
            value: '80000',
            nom: '80,000'
        },
        {
            value: '90000',
            nom: '90,000'
        },
        {
            value: '100000',
            nom: '100,000'
        },
        {
            value: '120000',
            nom: '120,000'
        },
        {
            value: '150000',
            nom: '150,000'
        },
        {
            value: '200000',
            nom: '200,000'
        },
        {
            value: '250000',
            nom: '250,000'
        },
        {
            value: '300000',
            nom: '300,000'
        },
        {
            value: '350000',
            nom: '350,000++'
        }
    
    ],
}
