import React from 'react'
import Image from 'next/image'
import LinkComponent from '@components/LinkComponent'
// import '../public/images/bangkok.png'

const LargeSwiperImage = ({ title, imageSrc, url = 'Pattaya' }) => {
    return (
        <LinkComponent href={`/properties?location=${url}`} className='group relative district-swiper-large-image w-[150px] h-[300px]'>
            <div className='w-[290px] h-[290px] relative overflow-hidden rounded-md'>
                <Image
                    src={`/images/pattaya.avif`}
                    fill={true}
                    className='object-cover'
                    alt={`Search properties in ${title}`}
                // style={{ objectFit: "contain" }}
                />
                <div className='absolute bottom-[-50px] group-hover:bottom-[50px] duration-150 left-0 right-0 rounded-b-md flex justify-center h-[100px] items-start bg-gradient-to-t from-maintransparent to-opacity-20 overflow-hidden'>
                    <span className='text-light z-10 px-1 rounded-sm text-xl text-center'>{title}</span>
                </div>
            </div>
        </LinkComponent>
    )
}

export default LargeSwiperImage