'use client'
import { useState, useEffect } from 'react';
import Image from 'next/image'
import FrontSearchBarWithInput from '@components/FrontSearchBarWithInput'

const Hero = ({ searchList, dictionary, searchParams, lang }) => {

    const [screenWidth, setScreenWidth] = useState(0);

    useEffect(() => {
      // Set the initial width
      setScreenWidth(window.innerWidth);
  
      // Update the width on window resize
      const handleResize = () => setScreenWidth(window.innerWidth);
  
      window.addEventListener('resize', handleResize);
  
      // Cleanup event listener on component unmount
      return () => window.removeEventListener('resize', handleResize);
    }, []);

  return (
    <div className='w-full pt-[-16] sm:h-[800px] h-screen relative overflow-hidden'>
        <div className='absolute flex flex-col z-20 top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] 2xl:w-8/12 w-full'>
            <div className='absolute w-full z-50 sm:bottom-[50%] sm:translate-y-[50%] bottom-1/3 flex flex-col justify-center'>
                <div className='w-full flex justify-center pb-4'>
                    <span className='home-title text-center text-2xl sm:text-[36px] font-bold sm:leading-[36px] tracking-wider'>{dictionary.hero.slogan1}</span>
                </div>
                <FrontSearchBarWithInput searchList={searchList} dictionary={dictionary} lang={lang} />
            </div>
        </div>
        <div className='absolute z-10 bg-blue-950 opacity-30 top-0 bottom-0 left-0 right-0'></div>
        <div className="relative h-full">
            <Image
                src={screenWidth < 500 ? '/images/newbannermobile.avif' : '/images/newbanner.avif'}
                // src={'/images/newbanner.avif'}
                fill={"true"}
                priority={"true"}
                placeholder='blur'
                blurDataURL={screenWidth < 500 ? '/images/blurmobile.avif' : '/images/blur.avif'}
                style={{
                    objectFit: 'cover'
                }}
                alt="Banner Image"
            />
        </div>
    </div>
  )
}

export default Hero