export const saleMinPrices = {
    "en": [
        {
            value: '0',
            nom: 'Any'
        },
        {
            value: '1000000',
            nom: ' 1,000,000'
        },
        {
            value: '2000000',
            nom: ' 2,000,000'
        },
        {
            value: '3000000',
            nom: ' 3,000,000'
        },
        {
            value: '4000000',
            nom: ' 4,000,000'
        },
        {
            value: '5000000',
            nom: ' 5,000,000'
        },
        {
            value: '6000000',
            nom: ' 6,000,000'
        },
        {
            value: '7000000',
            nom: ' 7,000,000'
        },
        {
            value: '8000000',
            nom: ' 8,000,000'
        },
        {
            value: '9000000',
            nom: ' 9,000,000'
        },
        {
            value: '10000000',
            nom: ' 10,000,000'
        },
        {
            value: '12000000',
            nom: ' 12,000,000'
        },
        {
            value: '15000000',
            nom: ' 15,000,000'
        },
        {
            value: '20000000',
            nom: ' 20,000,000'
        },
        {
            value: '25000000',
            nom: ' 25,000,000'
        },
        {
            value: '30000000',
            nom: ' 30,000,000'
        },
        {
            value: '35000000',
            nom: ' 35,000,000'
        }
    ],
    "th": [
        {
            value: '0',
            nom: 'อื่นๆ'
        },
        {
            value: '1000000',
            nom: ' 1,000,000'
        },
        {
            value: '2000000',
            nom: ' 2,000,000'
        },
        {
            value: '3000000',
            nom: ' 3,000,000'
        },
        {
            value: '4000000',
            nom: ' 4,000,000'
        },
        {
            value: '5000000',
            nom: ' 5,000,000'
        },
        {
            value: '6000000',
            nom: ' 6,000,000'
        },
        {
            value: '7000000',
            nom: ' 7,000,000'
        },
        {
            value: '8000000',
            nom: ' 8,000,000'
        },
        {
            value: '9000000',
            nom: ' 9,000,000'
        },
        {
            value: '10000000',
            nom: ' 10,000,000'
        },
        {
            value: '12000000',
            nom: ' 12,000,000'
        },
        {
            value: '15000000',
            nom: ' 15,000,000'
        },
        {
            value: '20000000',
            nom: ' 20,000,000'
        },
        {
            value: '25000000',
            nom: ' 25,000,000'
        },
        {
            value: '30000000',
            nom: ' 30,000,000'
        },
        {
            value: '35000000',
            nom: ' 35,000,000'
        }
    ],
    "cn": [
        {
            value: '0',
            nom: '无线'
        },
        {
            value: '1000000',
            nom: ' 1,000,000'
        },
        {
            value: '2000000',
            nom: ' 2,000,000'
        },
        {
            value: '3000000',
            nom: ' 3,000,000'
        },
        {
            value: '4000000',
            nom: ' 4,000,000'
        },
        {
            value: '5000000',
            nom: ' 5,000,000'
        },
        {
            value: '6000000',
            nom: ' 6,000,000'
        },
        {
            value: '7000000',
            nom: ' 7,000,000'
        },
        {
            value: '8000000',
            nom: ' 8,000,000'
        },
        {
            value: '9000000',
            nom: ' 9,000,000'
        },
        {
            value: '10000000',
            nom: ' 10,000,000'
        },
        {
            value: '12000000',
            nom: ' 12,000,000'
        },
        {
            value: '15000000',
            nom: ' 15,000,000'
        },
        {
            value: '20000000',
            nom: ' 20,000,000'
        },
        {
            value: '25000000',
            nom: ' 25,000,000'
        },
        {
            value: '30000000',
            nom: ' 30,000,000'
        },
        {
            value: '35000000',
            nom: ' 35,000,000'
        }
    ]
}