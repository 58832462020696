'use client'
import React from 'react'
import { GoogleMap, Marker, LoadScript } from "@react-google-maps/api";
import ContactUsForm from '@components/ContactUsForm';

const HomeMap = ({ latitude, longitude, dictionary, lang }) => {
  const containerStyle = {
    width: "100%",
    height: "700px",
  };

  const center = {
      lat: latitude,
      lng: longitude - 0.02,
  };

  const markerPos = {
      lat: latitude,
      lng: longitude,
  };

  const customMarkerIcon = {
      url: "/images/marker.png",

  };

  const options = {
      disableDefaultUI: true,
      zoomControl: true,
  };

  return (
    <div className='relative mb-3 pb-3 pt-2 md:h-[700px] h-auto'>
      {/* <div className='absolute z-20 bg-maincolor text-light flex flex-col translate-y-[50%]'>
        <h1 className='text-maincolor text-2xl font-bold'>Visit Us</h1>
      </div> */}
      <div className='z-20 sm:absolute relative left-0 right-0 top-0 bottom-0 flex items-center'>
        <div className='md:w-full 2xl:w-10/12 max-w-[1600px] w-full h-full py-6 flex md:flex-row flex-col flex-nowrap md:flex-wrap mx-auto px-0'>
            <div className='md:w-1/3 w-full bg-light rounded-md shadow-md p-6'>
                <h3 className='text-2xl text-maincolor'>{dictionary.contactUs.title}</h3>
                <ContactUsForm dictionary={dictionary.contactUs} lang={lang} />
            </div>
        </div>
      </div>
      <div className='sm:block hidden'>
        <LoadScript googleMapsApiKey={process.env.NEXT_PUBLIC_GOOGLE_MAPS_API}>
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={15} // Adjust the zoom level as needed
                options={options}
            >
                <Marker position={markerPos} icon={customMarkerIcon} className=' filter-maincolor' scaledSize={20} />
            </GoogleMap>
        </LoadScript>
      </div>
</div>
  )
}

export default HomeMap