'use client'
import { useState } from 'react'
import { RadioGroup } from '@headlessui/react'
import { serviceCondoRoomTypes } from '@constants/serviceCondoRoomTypes'

const roomTypes = serviceCondoRoomTypes

const HomeRoomFilter = ({ onChange, title, lang, dictionary }) => {
    let [room, setRoom] = useState(roomTypes[lang][0])
    let [addChecked, setAddChecked] = useState(roomTypes[lang][0].value)


    return (
        <div className="w-full z-1">
            <span className='text-maincolor text-md pb-2'>{dictionary.numberOfBedrooms}:</span>
            <div className="w-full z-1">
                <RadioGroup value={room} onChange={(value) => {
                    // console.log(value)
                    setRoom(value)
                    setAddChecked(value.value)
                    // handleUpdateParams(value)
                    onChange(value)
                }}
                    className='z-1 w-full flex flex-row mt-2 rounded-lg shadow-md border-[1px] border-forth overflow-hidden'
                >
                    {roomTypes[lang].map((roomType) => (
                        <RadioGroup.Option
                            key={roomType.value}
                            value={roomType}
                            checked={roomType.value == addChecked}
                            className={({ active, checked }) =>
                                `${active
                                    ? ''
                                    : ''
                                }
                                    ${checked ? 'bg-maincolor text-light' : 'bg-light'
                                }
                                w-full z-1 flex cursor-pointer px-2 py-2 shadow-md focus:outline-none`
                            }
                        >
                            {({ active, checked }) => (
                                <>
                                    <div className="flex w-full items-center justify-center">
                                        <div className="flex items-center">
                                            <div className="text-sm text-center">
                                                <RadioGroup.Label
                                                    as="p"
                                                    className={`font-medium w-full  ${checked ? 'text-light' : 'text-maincolor'
                                                        }`}
                                                >
                                                    {roomType.title}
                                                </RadioGroup.Label>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </RadioGroup.Option>
                    ))}
                </RadioGroup>
            </div>
        </div>
    )
}

export default HomeRoomFilter