'use client'
import { useState, useEffect } from 'react'
import { priceFormatter } from "@utils";
import { Combobox } from "@headlessui/react"
import { rentMaxPrices } from '@constants/rentMaxPrices'
import { RiArrowDropDownLine } from 'react-icons/ri'
import tailwindConfig from '@tailwind.config'



const HomeMaxRentPriceFilter = ({ onChange, maxPrice, title, dictionary, lang }) => {

    const [selectedMaxPrice, setSelectedMaxPrice] = useState(maxPrice || rentMaxPrices[lang].at(-1))
    const [query, setQuery] = useState('')



    useEffect(() => {
        setSelectedMaxPrice(maxPrice)
    }, [setSelectedMaxPrice])

    const filteredPrices =
        query === ''
        ? rentMaxPrices[lang]
        : rentMaxPrices[lang].filter((price) => {
            return price.value.toLowerCase().includes(query.toLowerCase())
        })
        // query === ''
        //     ? rentMaxPrices[lang]
        //     : rentMaxPrices[lang].filter((price) => {
        //         if (getSearchParams('minprice') == null) {
        //             return price.value.includes(query.toLowerCase())
        //         } else {
        //             return (price.value.includes(query.toLowerCase()) && Number(price.value) >= Number(getSearchParams('minprice')))

        //         }
        //     })

    const selectTextOnClick = (e) => {
        e.target.select();
    };

    return (
        <div className='flex flex-col w-full mt-2'>
            <span className='text-md text-secondary mb-1'>{dictionary.maxPrice}:</span>
            <Combobox 
                value={selectedMaxPrice} 
                onChange={(e) => {
                        setSelectedMaxPrice(e)
                        onChange(e)
                    }
                }
            >
                <div className="relative w-full">
                    <div className='z-1 relative w-full cursor-default overflow-hidden rounded-lg shadow-md bg-white text-left border-[1px] border-forth text-secondary focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 sm:text-sm'>
                        <Combobox.Input 
                        onClick={selectTextOnClick} 
                        onChange={(event) => { setQuery(event.target.value) }} 
                        displayValue={(selectedMaxPrice) => selectedMaxPrice?.nom}
                        required 
                        className='w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0' onFocus={() => {
                    }} />
                        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                            <RiArrowDropDownLine
                                color={tailwindConfig.theme.extend.colors.secondary}
                                size={28}
                            />
                        </Combobox.Button>
                    </div>
                    <Combobox.Options
                        as="ul" // Render the Combobox.Options as an unordered list (ul) to contain the options
                        className='z-50 left-0 right-0 absolute bg-white h-auto max-h-[120px] overflow-y-auto shadow-md rounded-md py-1'
                    >
                        {query.length > 0 && (
                            <Combobox.Option
                                value={{ nom: priceFormatter(query), value: query }}
                                className='py-2 px-4 cursor-pointer color-secondary z-50'

                            >
                                Search price: {priceFormatter(query)}
                            </Combobox.Option>
                        )}
                        {filteredPrices.map((price) => (
                            <Combobox.Option
                                key={price.value}
                                value={price}
                                className='py-2 px-4 cursor-pointer color-secondary z-50'
                            >
                                {price.nom}
                            </Combobox.Option>
                        ))}
                    </Combobox.Options>
                </div>
            </Combobox>
        </div>
    )
}

export default HomeMaxRentPriceFilter